import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { useSpring, animated } from 'react-spring/web.cjs'; // web.cjs is required for IE 11 support
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
	},
}));

const Fade = React.forwardRef(function Fade(props, ref) {
	const { in: open, children, onEnter, onExited, ...other } = props;
	const style = useSpring({
		from: { opacity: 0 },
		to: { opacity: open ? 1 : 0 },
		onStart: () => {
			if (open && onEnter) {
				onEnter();
			}
		},
		onRest: () => {
			if (!open && onExited) {
				onExited();
			}
		},
	});

	return (
		<animated.div ref={ref} style={style} {...other}>
			{children}
		</animated.div>
	);
});

export default function Changelog() {
	const classes = useStyles();
	const [open, setOpen] = React.useState(true);
	const [openedOnce, setOpenedOnce] = React.useState(false);
	const query = new URLSearchParams(useLocation().search);


	const handleClose = () => {
		setOpen(false);
		setOpenedOnce(true);
	};

	if (!query.has('changelog')) return <></>;
	if (openedOnce) return <></>;

	return (
		<div>
			<Modal
				aria-labelledby="spring-modal-title"
				aria-describedby="spring-modal-description"
				className={classes.modal}
				open={open}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={open}>
					<div className={classes.paper}>
						<h2>Changelog PTMD 2.0</h2>
						<p>Graag even doornemen</p>
						<span className="font-weight-bold mb-2">Verbetert functies (Graag testen)</span>
						<span className="fs-13">- algemene laadsnelheden</span>
						<span className="fs-13">- Zoekfuncties *GROOTSTE VERBETERING* (Homepagina en browse pagina)</span>
						<span className="fs-13">- browsepagina (Zoekt zoals PTC en is sneller)</span>
						<span className="fs-13">- itempagina (Heeft nu maten en gerelateerde producten)</span>
						<span className="fs-13">- Catalogus pagina (Je kan nu op items klikken in het boek)</span>
						<span className="fs-13">- storelocator</span>
						<span className="fs-13">- Misc pagina's zoals FAQ, Contact, etc.</span>
						<span className="fs-13">- Geforceerde taal kan nu meegegeven worden met (?language=de) in de url</span>
						<span className="fs-13">- Taal is browser based, prijzen zijn GEO IP based, IP niet zichtbaar? Prijzen dan ook niet.</span>
						<span className="fs-13 ml-2">{'  '} Forceer prijzen met ?forcePriceOn=true (/browse?forcePriceOn=true) op browse pagina.</span>
						<span className="fs-13">- Verbeterde 404</span>

						<span className="font-weight-bold mb-2 mt-3">Pagina's die nog hetzelfde zijn</span>
						<span className="fs-13">- Over ons</span>
						<span className="fs-13">- Vacatures</span>
						<span className="fs-13">- Press</span>
						<span className="fs-13">- Online partners</span>

						<span className="font-weight-bold mb-2 mt-3">TODO</span>
						<span className="fs-13">- Telefoon optimalisatie</span>
						<span className="fs-13">- 100% vertaling in alle talen</span>
						<span className="fs-13">- Storelocator shops pagina's</span>
						<span className="fs-13">- Google PageSpeed Insights</span>
					</div>
				</Fade>
			</Modal>
		</div>
	);
}
