import React from 'react';
import { Helmet } from 'react-helmet';

export const InitHelmet = props => {
	return <Helmet>
		<meta charSet="utf-8" />
		<title>{props.title}</title>
		<link rel="canonical" href={`https://ptmd.nl${props.overridePath || props.path}`}/>
		<meta name="description" content={props.desc} />
	</Helmet>;
};

export default function HelmetHoc(props) {
	if (props.title && !props.customHelmet) {
		return (
			<div>
				<InitHelmet
					title={props.title}
					overridePath={props.overridePath}
					path={props.path}
					desc={props.desc}
				/>
				{props.children}
			</div>
		);
	}
	return props.children;
}
