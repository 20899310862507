import React, { createContext, useReducer } from 'react';

const Reducer = (state, action) => {
	switch (action.type) {
		case 'SET_LOCALE':
			if (action.append) {
				return action.append(state);
			}
			return {
				...state,
				locale: {...action.payload, doneAsking: true}
			};
		case 'SET_CATS':
			return {
				...state,
				categoriesWithProductgroups: action.payload
			};
		case 'SET_FOOTERBASIC':
			return {
				...state,
				basicFooter: action.payload
			};
		default:
			return state;
	}
};

const initialState = {
	locale: {
		country: '',
		accurate: false,
		doneAsking: false
	},
	categoriesWithProductgroups: [],
	basicFooter: false
};

const Store = ({ children }) => {
	const [state, dispatch] = useReducer(Reducer, initialState);
	return (
		<Context.Provider value={[state, dispatch]}>
			{children}
		</Context.Provider>
	);
};

export const Context = createContext(initialState);
export default Store;
