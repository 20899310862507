import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import './Drawer.scss';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import EventSeatIcon from '@material-ui/icons/EventSeat';
import MenuIcon from '@material-ui/icons/Menu';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import BusinessIcon from '@material-ui/icons/Business';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import { getRightCL } from './util/lang/i18n';

const useStyles = makeStyles({
	list: {
		width: 250,
	},
});

const options = [
	{
		href: getRightCL('/'),
		label: 'Home',
		Icon: HomeIcon
	},
	{
		href: getRightCL('/browse'),
		label: 'Browse',
		Icon: EventSeatIcon
	},
	{
		href: getRightCL('/locator'),
		label: 'Store locator',
		Icon: LocationOnIcon
	},
	{
		href: getRightCL('/b2b'),
		label: 'B2B',
		Icon: BusinessIcon
	},
	{
		href: getRightCL('/press'),
		label: 'Press',
		Icon: PhotoCameraIcon
	},
];

export default function TemporaryDrawer() {
	const classes = useStyles();
	const [state, setState] = React.useState({
		left: false,
	});

	const toggleDrawer = (anchor, open) => (event) => {
		if (
			event.type === 'keydown' &&
			(event.key === 'Tab' || event.key === 'Shift')
		) {
			return;
		}

		setState({ ...state, [anchor]: open });
	};

	const preventDefault = (event) => event.preventDefault();

	return (
		<>
			<Drawer
				anchor={'left'}
				open={state['left']}
				onClose={toggleDrawer('left', false)}
			>
				<div className={clsx(classes.list)} role="presentation">
					<List>
						{
							options.map(option => {
								const { Icon } = option;
								return <><Link to={option.href} onClick={preventDefault}>
									<ListItem button onClick>
										<ListItemIcon>
											<Icon />
										</ListItemIcon>
										<ListItemText primary={option.label} style={{ color: 'black' }} />
									</ListItem>
								</Link>
								<Divider />
								</>;
							})
						}
					</List>
				</div>
			</Drawer>
			<div className='drawer justify-content-between d-flex'>
				<Button onClick={toggleDrawer('left', true)} style={{ backgroundColor: 'rgb(0, 0, 0, 0.2)', padding: '10px' }} className='m-3'>
					<MenuIcon style={{ color: 'white' }} fontSize='large' />
				</Button>
			</div>
		</>
	);
}
