import { supportedLangs } from './i18n';

export default {
	name: 'customDetector',
	lookup() {
		const url = window.location.pathname.split('/').filter(i => i && i.length);
		const lng = Object.keys(supportedLangs).find(lang => lang === url[1]);
		const localLang = localStorage.getItem('i18nextLng');
		const browserLang = Object.keys(supportedLangs).find(l => l === window.navigator.language.slice(0, 2).toLowerCase());
		return lng || localLang || browserLang;
	},
	cacheUserLanguage() {
		//do nothing
	},
};
