import React, { useContext, useEffect, useRef, useState } from 'react';
import './mailSignup.scss';
import SpringModal from './util/Modal';
import { useTranslation } from 'react-i18next';
import { newsLetter } from '../api';
import { Context } from '../main/State';
import { areWeNowIn } from './util/lang/i18n';

const mailSignupTimeout = 120000;
const closeTimout = 900;

export const SignupForMail = ({ openBanner }) => {
	const { t } = useTranslation();
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');

	const submit = () => {
		newsLetter(email)
			.then(() => {
				setMessage('newsletter.Success');
				setTimeout(() => {
					if (openBanner) {
						openBanner(false);
					}
				}, closeTimout);
			})
			.catch(() =>
				setMessage(
					'Error, uw mail klopt niet of staat mogelijk al in onze lijst.'
				)
			);
	};

	return (
		<div className="d-flex flex-column align-items-center">
			<div className="input-holder">
				<input
					value={email}
					required
					onChange={(e) => setEmail(e.target.value)}
					className="field"
					placeholder={t('newsletter.E-mail Address')}
				/>
				<button className="button" onClick={submit}>
					{t('newsletter.Sign Up')}
				</button>
			</div>
			<span className="mt-3 fs-13">{t(message)}</span>
		</div>
	);
};

export const MailSignup = () => {
	const [bannerOpen, openBanner] = useState(false);
	const { t } = useTranslation();
	const [state] = useContext(Context);
	const stateRef = useRef();
	stateRef.current = state;

	useEffect(() => {
		setTimeout(() => {
			if (
				areWeNowIn(stateRef.current, ['nl']) &&
        !window.location.href.includes('localhost')
			) {
				openBanner(true);
			}
		}, mailSignupTimeout);
	}, []);

	return (
		<SpringModal
			open={bannerOpen}
			background="white"
			className="rounded"
			onClose={() => openBanner(false)}
		>
			<div
				className="d-flex flex-column align-items-center signup"
				style={{ minWidth: '300px' }}
			>
				<div className="w-100 d-flex justify-content-end">
					<button className="close-button" onClick={() => openBanner(false)}>
            X
					</button>
				</div>
				<div className="title">{t('newsletter.Stay up to date')}</div>
				<div className="fs-13 mb-5 text-center">
					{t('newsletter.Sign Up Text')}
				</div>
				<SignupForMail openBanner={openBanner} />
			</div>
		</SpringModal>
	);
};
