import { createMuiTheme } from '@material-ui/core';

export const generalTheme = createMuiTheme({
	palette: {
		type: 'light',
		primary: {
			main: '#e8c56a'
		},
		secondary: {
			main: '#FFF'
		}
	},
	typography: {
		fontFamily: [
			'Open Sans',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(','),
		h3: {
			color: 'white',
			fontWeight: 500,
			fontFamily: 'Futura Book'
		},
		subtitle1: {
			color: 'white',
		},
		subtitle2: {
			fontWeight: '100',
		},
	},
	overrides: {
		MuiButton: {
			root: {
				justifyContent: 'none',
			},
		},
	},
});
