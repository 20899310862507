import React, { Suspense, useContext } from 'react';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
	useParams,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

//import all pages
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import StickyPTMDLogo from '../Components/StickyPTMDLogo';
import { Context } from './State';
import { areWeNowIn } from '../Components/util/lang/i18n';
import { Hidden } from '@material-ui/core';
import { AnimatePresence } from 'framer-motion';
import HelmetHoc from'./../Components/util/analytics/Helmet';
import BasicAnimationHOC from './../Components/util/animations';
import Drawer from '../Components/Drawer';
import Init from './Init';

const Landing = React.lazy(() => import('../pages/landing/Landing'));
const Shop = React.lazy(() => import('../pages/browse/Shop'));
const FAQ = React.lazy(() => import('../pages/faq/faq'));
const TermsAndConditions = React.lazy(() => import('../pages/termsandconditions/TermsAndConditions'));
const Complaints = React.lazy(() => import('../pages/complaints/Complaints'));
const Warranty = React.lazy(() => import('../pages/warranty/Warranty'));
const Contact = React.lazy(() => import('../pages/contact/Contact'));
const NotFound = React.lazy(() => import('../pages/notFound/NotFound'));
const Catalog = React.lazy(() => import('../pages/catalog/Catalog'));
const CountryStoreLocator = React.lazy(() => import('../pages/locator/CountryStoreLocator'));
const JobOpenings = React.lazy(() => import('./../pages/jobs/Jobs'));
const About = React.lazy(() => import('../pages/about/About'));
const Press = React.lazy(() => import('../pages/press/Press'));
const Partners = React.lazy(() => import('../pages/partners/Partners'));
const Campaign = React.lazy(() => import('../pages/campaign/Campaign'));

const routes = [
	{
		path: '/:language/browse/item/:sku',
		component: Shop,
		helmet: {
			title: 'PTMD Webshop | item bekijken',
			desc: 'Koop hier de nieuwste PTMD Collectie!',
			overridePath: '/browse',
			customHelmet: true,
		},
	},
	{
		path: '/:language/elements',
		component: Campaign,
		helmet: {
			title: 'PTMD | Elements',
			desc: 'PTMD Elements',
		},
	},
	{
		path: '/:language/catalog/:catalogCode',
		component: Catalog,
		helmet: {
			title: 'PTMD Catalogi',
			desc: 'Laat u inspireren door de nieuwste PTMD catalogi.',
			overridePath: '/catalog',
		},
	},
	{
		path: '/:language/locator',
		component: () => <CountryStoreLocator />,
		helmet: {
			title: 'PTMD Storelocator',
			desc: 'Laat u inspireren in jouw dichtstbijzijnde winkel.',
		},
	},
	{
		path: '/:language/faq',
		component: FAQ,
		helmet: {
			title: 'PTMD FAQ',
			desc: 'Frequently asked questions.',
		},
	},
	{
		path: '/:language/termsandconditions',
		component: TermsAndConditions,
		helmet: {
			title: 'PTMD Algemene voorwaarden',
			desc: 'Algemene voorwaarden',
		},
	},
	{
		path: '/:language/complaints',
		component: Complaints,
		helmet: {
			title: 'PTMD Klachten en suggesties',
			desc: 'Klachten en suggesties',
		},
	},
	{
		path: '/:language/warranty',
		component: Warranty,
		helmet: {
			title: 'PTMD Garantie',
			desc: 'Onze garantie voorwaarden.',
		},
	},
	{
		path: '/:language/about',
		component: About,
		helmet: {
			title: 'PTMD About Us',
			desc: 'Een merk, een missie, een community.',
		},
	},
	{
		path: '/:language/press',
		component: Press,
		helmet: {
			title: 'PTMD Press',
			desc: 'Werk samen met PTMD voor de beste persberichten.',
		},
	},
	{
		path: '/:language/partners',
		component: Partners,
		helmet: {
			title: 'PTMD Partners',
			desc: 'PTMD Partners',
		},
		isPartners: true
	},
	{
		path: '/:language/jobs',
		component: JobOpenings,
		helmet: {
			title: 'PTMD Vacatures',
			desc: 'Op zoek naar een droombaan? Wij zoeken collega\'s!',
		},
	},
	{
		path: '/:language/contact',
		component: Contact,
		helmet: {
			title: 'PTMD Contact',
			desc: 'Heeft u een vraag? Vraag het gewoon!',
		},
	},
	{
		path: '/:language/notFound',
		component: NotFound,
		helmet: {
			title: 'Niet gevonden',
			desc: 'Sorry, de pagina die je zocht kon niet gevonden worden.',
		},
	},
	{
		path: '/:language/b2b',
		exact: true,
		component: () => {
			window.location.href = 'https://b2b.ptmd.nl/shop/login';
			return null;
		},
	},
	{
		path: '/:language',
		helmet: {
			title: 'PTMD Collection',
			desc:
        'PTMD Collection is een inspirerend merk in woonaccessoires en meubels: stoer, stijlvol en uniek! Bezoek jouw dichtstbijzijnde winkel of bestel online.',
		},
		exact: true,
		component: Landing,
	},
	{
		path: '/',
		helmet: {
			title: 'PTMD Collection',
			desc:
        'PTMD Collection is een inspirerend merk in woonaccessoires en meubels: stoer, stijlvol en uniek! Bezoek jouw dichtstbijzijnde winkel of bestel online.',
		},
		exact: true,
		component: Landing,
	},
];

const redirectTo = to => () => {
	window.location.assign(to);
};

function LanguageRedirect({ to }) {
	const { i18n } = useTranslation();
	const { language = i18n.language } = useParams();
	return <Redirect to={`/${language}${to}`} />;
}

export default () => {
	const [state] = useContext(Context);
	return (
		<Router>
			<Init></Init>
			<Hidden smDown>
				<Route>
					<Navbar />
				</Route>
			</Hidden>
			<Hidden smUp>
				<Drawer />
				<StickyPTMDLogo />
			</Hidden>
			<AnimatePresence exitBeforeEnter>
				<Switch>
					<Route path="/:language/instagram" render={redirectTo('https://instagram.com/ptmdcollection')} />
					<Route path="/:language/facebook" render={redirectTo('https://facebook.com/ptmdcollection')} />
					<Route path="/:language/pinterest" render={redirectTo('https://pinterest.com/ptmdcollection')} />
					<Route path="/:language/ariane" render={() => <LanguageRedirect to="/catalog/5fe303c2c60697243cab4344?page=9" />} />
					<Route path="/:language/carise" render={() => <LanguageRedirect to="/catalog/5fe303c2c60697243cab4344?page=4" />} />
					{routes.map((route, i) => {
						if(route.isPartners && !areWeNowIn(state, ['nl', 'be'])){
							return null;
						}
						return (
							<Route
								key={i}
								exact={route.exact}
								path={route.path}
							><HelmetHoc {...route.helmet} path={route.path}>
									<BasicAnimationHOC>
										<Suspense fallback={<div><p>loading...</p></div>}>
											<route.component/>
										</Suspense>
									</BasicAnimationHOC>
								</HelmetHoc>
							</Route>
						);
					})}
					<Route render={() => <LanguageRedirect to="/notFound" />} />
				</Switch>
			</AnimatePresence>
			<Footer />
		</Router>
	);
};
