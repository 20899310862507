import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './en';
import nl from './nl';
import de from './de';
import fr from './fr';
import es from './es';
import custom from './custom';

export const supportedLangs = {
	nl,
	en,
	de,
	fr,
	es,
};

const localeMapping = {
	nl: 'NLD',
	'en-GB': 'ENU',
	de: 'DEU',
	fr: 'FRA',
	es: 'ESP',
	en: 'ENU',
	gb: 'ENU',
};

export const availableLanguages = [
	{
		symbol: 'EN',
		lang: 'English',
	},
	{
		symbol: 'NL',
		lang: 'Nederlands',
	},
	{
		symbol: 'DE',
		lang: 'Deutsch',
	},
	{
		symbol: 'FR',
		lang: 'Francais',
	},
	{
		symbol: 'ES',
		lang: 'Español',
	},
];

export const availableCountries = [
	{
		symbol: 'gb',
		lang: 'en',
	},
	{
		symbol: 'nl',
		lang: 'nl',
	},
	{
		symbol: 'de',
		lang: 'de',
	},
	{
		symbol: 'be',
		lang: 'nl',
	},
	{
		symbol: 'es',
		lang: 'es',
	},
];

export const defaultLang = 'en';
export const defaultCountry = 'nl';

// get right country and language
export const getRightCL = (url) => {
	const pathArray = window.location.pathname.split('/').filter((a) => a.length);
	return `/${pathArray[0]}${url}`;
};

export const areWeNowIn = (state, countries) => countries.find((a) => a.includes(state?.locale?.country.toLowerCase()));

export const getDesc = (key, item, country) => {
	const localeMap = localeMapping[country];
	const desc =
    item[`${key}.${localeMap}`] ||
    item[`${key}.${localeMapping[defaultLang]}`] ||
    item[key];
	return desc;
};

export const changeCountry = (country) => {
	const pathArray = window.location.pathname.split('/').filter((a) => a.length);
	pathArray[0] = country;
	const url = pathArray.join('/');
	return `/${url}${window.location.search}`;
};

export const changeLanguage = (lang) => {
	const pathArray = window.location.pathname.split('/').filter((a) => a.length);
	pathArray.shift();
	const url = pathArray.join('/');
	return `/${lang}/${url}${window.location.search}`;
};

export const getLocaleMapping = (lang) => {
	const locale = localeMapping[lang];
	if (!locale) return localeMapping[defaultLang];
	return locale;
};

const resources = Object.keys(supportedLangs).reduce((langs, lang) => {
	langs[lang] = {
		lang: supportedLangs[lang],
	};

	return langs;
}, {});

const langDec = new LanguageDetector();
langDec.addDetector(custom);
i18n
	.use(langDec)
	.use(initReactI18next)
	.init(
		{
			resources,
			fallbackLng: {
				default: ['en'],
				'en-US': ['en'],
			},
			debug: false,

			// have a common namespace used around the full app
			ns: ['lang'],
			defaultNS: 'lang',

			keySeparator: false, // we use content as keys

			interpolation: {
				escapeValue: false, // not needed for react!!
				formatSeparator: ',',
			},

			react: {
				wait: true,
			},

			// // Language detection
			detection: {
				order: ['customDetector', 'localStorage', 'cookie'],
				caches: ['localStorage', 'cookie'],
			},
		}
	);

export default i18n;
