import React from 'react';
import { motion } from 'framer-motion';

const defaultPageAnimation = {
	in: {
		opacity: 1,
		y: 0
	},
	out: {
		opacity: 0,
		y: '-20px'
	}
};

export const defaultHeroAnimation = {
	inHeader: {
		opacity: 1,
		transition: {duration: 2},
		y: 0
	},
	outHeader: {
		opacity: 0,
	},
	in: {
		opacity: 1,
		transition: {duration: 3},
		y: 0
	},
	out: {
		opacity: 0,
		y: '-10px'
	},
};

export const searchOptionsAnimation = {
	in: {
		opacity: 1,
		transition: {duration: 0.2},
		display: 'block',
		y: 0
	},
	out: {
		opacity: 0,
		transition: {duration: 0.2},
		display: 'none',
		y: '-10px'
	}
};

export default function BasicAnimationHOC(props) {
	return (
		<motion.div
			initial='out'
			animate='in'
			exit='out'
			variants={defaultPageAnimation}
		>{props.children}</motion.div>
	);
}
