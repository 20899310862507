import React from 'react';
import {
	Tooltip,
	List,
	ListItem,
	makeStyles,
	Zoom,
	withStyles,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		maxWidth: 360,
		backgroundColor: theme.palette.background.paper,
	},
}));

const LightTooltip = withStyles(() => ({
	tooltip: {
		backgroundColor: 'white',
		color: 'rgba(0, 0, 0, 0.87)',
		fontSize: 11,
	},
}))(Tooltip);

function ListItemLink(props) {
	const { to, externalHref } = props;

	const renderLink = React.useMemo(
		() =>
			React.forwardRef((itemProps, ref) =>
				<Link to={to} ref={ref} {...itemProps} />
			),
		[to]
	);

	if (externalHref) {
		return (
			<li>
				<a target="_blank" rel="noopener noreferrer" href={to}>
					{' '}
					<ListItem button>{props.text}</ListItem>
				</a>
			</li>
		);
	}

	return (
		<li>
			<ListItem button component={renderLink}>
				{props.text}
			</ListItem>
		</li>
	);
}

export const MenuTooltip = (props) => {
	const { Icon, options, disableArrow, arrowClassName } = props;
	const classes = useStyles();

	return (
		<LightTooltip
			TransitionComponent={Zoom}
			title={
				<div className={classes.root}>
					<List component="nav" aria-label="main mailbox folders">
						{options &&
							options.length &&
							options
								.filter((m) => !m.disableRender)
								.map((option, i) => {
									const {
										text,
										href,
										externalHref,
										onClick,
										className,
									} = option;
									if (href) {
										return (
											<ListItemLink
												externalHref={externalHref}
												to={href}
												key={i}
												text={text}
											/>
										);
									} else if (onClick) {
										return (
											<ListItem
												key={i}
												button
												component="span"
												className={className}
												onClick={onClick}
											>
												{text}
											</ListItem>
										);
									}
									return undefined;
								})}
					</List>
				</div>
			}
			interactive
		>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				{Icon && <Icon className="navbar-menu-item-icon" />}{' '}
				{options && !disableArrow &&
					<ArrowDropDownIcon
						className={arrowClassName}
						style={{ color: 'black' }}
					/>
				}
			</div>
		</LightTooltip>
	);
};

export default (props) => {
	const { Icon, options, href } = props;

	if (options) {
		return (
			<span className="navbar-menu-item">
				<MenuTooltip {...props} />
			</span>
		);
	}

	return (
		<>
			<Link to={href}>
				<span className="navbar-menu-item">
					{Icon && <Icon className="navbar-menu-item-icon" />}
				</span>
			</Link>
		</>
	);
};
