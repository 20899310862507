import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import { Snackbar, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { Context } from './State';
import {
	defaultCountry,
	availableLanguages,
} from './../Components/util/lang/i18n';
import { categories, whereAmI } from '../api';
import Changelog from '../Components/extra/Changelog';
import { MailSignup } from '../Components/MailSignup';
import { useHistory } from 'react-router-dom';
import SpringModal from '../Components/util/Modal';

const useStyles = makeStyles({
	snackbarStyleViaContentProps: {
		background: 'rgb(20, 20, 20)',
		color: 'white',
		fontSize: '10px',
	},
});

const initID = 'UA-102066913-3';
const updateID = 'qwe';

export const firaConversion = {
	category: 'partner',
	action: 'doorgestuurd naar partner',
	label: 'fira',
};

export const wneConversion = {
	category: 'partner',
	action: 'doorgestuurd naar partner',
	label: 'wantsandneeds',
};

export const catalogConversion = (catalog, sku) => ({
	category: 'catalog',
	action: 'Via catalog bij item gekomen',
	label: `Via de ${catalog} catalog, naar item ${sku} gegaan`,
});

export const usedStoreLocator = (place) => ({
	category: 'storeLocator',
	action: 'storeLocation gebruikt',
	label: place,
});

export const usedSearchBar = (location, searchTerm) => ({
	category: 'searchBar',
	action: `Zoekbalk op de ${location} gebruikt`,
	label: `Gezocht naar: "${searchTerm}"`,
});

export const changedLanguage = (lang) => ({
	category: 'taal',
	action: 'Taal veranderd',
	label: `Veranderd naar: "${lang}"`,
});

export const usedSort = (sortOption) => ({
	category: 'sorteren',
	action: 'Gesorteerd',
	label: `Gesorteerd op: "${sortOption}"`,
});

const Analytics = () => {
	const location = useLocation();

	useEffect(() => {
		ReactGA.initialize(initID);
	}, []);

	// log location change
	useEffect(() => {
		ReactGA.pageview(location.pathname + location.search);
	}, [location.pathname, location.search]);

	return <></>;
};

const es = 'ptmd.es';

export const GeoIP = () => {
	const [, dispatch] = useContext(Context);
	const history = useHistory();
	const { i18n } = useTranslation();
	const [openLangSwitchModal, setOpenLangSwitchModal] = useState({});
	const currentPath = history.location.pathname
		.split('/')
		.filter((a) => a.length);
	const isOnHome = currentPath.length === 1 || currentPath.length === 0;

	const pushToHistory = (override) => {
		history.push({ pathname: `/${override || i18n.language}` });
	};

	const setRightCL = () => {
		if (
			currentPath &&
      currentPath[0] &&
      availableLanguages.find((al) => al.symbol.toLowerCase() === currentPath[0].toLowerCase())
		) {
			i18n.changeLanguage(currentPath[0]);
			localStorage.setItem('i18nextLng', currentPath[0]);
		}
		const ultraForce = localStorage.getItem('ultraForce');
		let country = '';
		if (ultraForce) {
			country = `country=${ultraForce.toLowerCase()}`;
		} else if (window.location.href.includes(es)) {
			country = 'country=es';
			localStorage.setItem('country', 'es');
			if (isOnHome) {
				pushToHistory('es');
			}
			return dispatch({
				type: 'SET_LOCALE',
				payload: { country: 'ES', collection: 'website-es', accurate: true },
			});
		}
		whereAmI(country).then((payload) => {
			if (ultraForce) {
				payload.accurate = false;
			}
			dispatch({ type: 'SET_LOCALE', payload });
			localStorage.setItem('country', payload.country.toLowerCase());
			if (isOnHome) {
				pushToHistory();
			}
		}).catch((err) => {
			// hier kijken of browser onder beschikbare talen valt en die zetten met accurate false. Als niet in lijst staat dan nl
			console.log(err, 'Can\'t determine language.');
			dispatch({
				type: 'SET_LOCALE',
				payload: { country: defaultCountry, accurate: false },
			});
			const localCountry = localStorage.getItem('country');
			if (isOnHome) pushToHistory();
			if (!localCountry) {
				localStorage.setItem('country', 'nl');
			}
		});
	};

	const acceptCountryChange = () => {
		// als land is geswitcht accurate moet false zijn.
		const payload = {
			accurate: false,
			country: openLangSwitchModal.to,
		};
		dispatch({ type: 'SET_LOCALE', payload });
		localStorage.setItem('country', payload.country.toLowerCase());
		setOpenLangSwitchModal({});
		if (isOnHome) {
			pushToHistory();
		}
	};

	const stayInCurrentCountry = () => {
		const localCountry = localStorage.getItem('country');
		const payload = {
			accurate: true,
			country: localCountry,
		};
		dispatch({ type: 'SET_LOCALE', payload });
		localStorage.setItem('country', payload.country.toLowerCase());
		setOpenLangSwitchModal({});
		if (isOnHome) {
			pushToHistory();
		}
	};

	const setUpdateId = async () => {
		const lUI = localStorage.getItem('updateID');
		if (lUI && lUI !== updateID) {
			localStorage.clear();
			localStorage.setItem('updateID', updateID);
			window.location.reload();
		} else {
			localStorage.setItem('updateID', updateID);
		}
	};

	const setup = async () => {
		await setUpdateId();
		setRightCL();
	};

	useEffect(() => {
		setup();
	}, []);

	return (
		<SpringModal
			open={'from' in openLangSwitchModal}
			background="white"
			className="rounded">
			{
				'from' in openLangSwitchModal && <div className="w-100 h-100 d-flex flex-column align-items-center">
					<span className="fs-13">
							It looks like you are in{' '}
						{(openLangSwitchModal.to || '').toUpperCase()},
					</span>
					<span className="fs-13 mb-4">
					Right now, this website is in{' '}
						{(openLangSwitchModal.from || '').toUpperCase()} Mode.
					</span>
					<Button
						color="primary"
						fullWidth
						className="mb-2 mr-2"
						variant="contained"
						disableElevation
						size="small"
						onClick={() => {
							stayInCurrentCountry();
						}}>
						Stay in {(openLangSwitchModal.from || '').toUpperCase()}
					</Button>
					or
					<Button
						color="secondary"
						fullWidth
						className="mt-2"
						variant="contained"
						disableElevation
						size="small"
						onClick={() => acceptCountryChange()}
					>
					Switch to {(openLangSwitchModal.to || '').toUpperCase()}
					</Button>
				</div>
			}
		</SpringModal>
	);
};

export const CatagoryInit = () => {
	const [, dispatch] = useContext(Context);

	useEffect(() => {
		categories().then((payload) => {
			dispatch({ type: 'SET_CATS', payload });
		}).catch(err => console.error(err));
	}, []);
	return <></>;
};

export default ({ children }) => {
	const classes = useStyles();
	const [open, setOpen] = useState(true);
	const { t } = useTranslation();
	const [cookiesAccepted, setCookiesAccepted] = useState(false);

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpen(false);
	};

	const acceptCookies = () => {
		setCookiesAccepted(true);
		localStorage.setItem('consent', 'yes');
		handleClose();
	};

	useEffect(() => {
		if (localStorage.getItem('consent') === 'yes') {
			setCookiesAccepted(true);
		}
	}, []);

	return (
		<>
			{cookiesAccepted && <Analytics />}
			<CatagoryInit />
			<GeoIP />
			<Changelog />
			<MailSignup />
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				open={cookiesAccepted ? false : open}
				onClose={handleClose}
				ContentProps={{
					className: classes.snackbarStyleViaContentProps,
				}}
				message={t('cookies note')}
				action={
					<React.Fragment>
						<Button
							color="secondary"
							className="mb-2 mr-2 fs-13"
							variant="contained"
							disableElevation
							size="small"
							onClick={acceptCookies}
						>
							{t('cookies accepteren')}
						</Button>
						<Button
							color="secondary"
							size="small"
							className="mb-2 fs-13"
							onClick={handleClose}
						>
							{t('cookies weigeren')}
						</Button>
					</React.Fragment>
				}
			/>
			{children}
		</>
	);
};

export const createLink = ({ pathname, query }) => {
	let result = pathname;
	if (query) {
		result = `${result}?${query}`;
	}
	return result;
};
