import './Navbar.scss';
import React, { useContext, useEffect, useState } from 'react';
import {
	Container,
	AppBar,
	Toolbar,
	makeStyles,
	Button,
} from '@material-ui/core';
import { motion } from 'framer-motion';
import InfoIcon from '@material-ui/icons/Info';
import MenuItem from './inputs/MenuItem';
import { whereAmI } from '../api';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { event } from 'react-ga';
import { changedLanguage } from '../main/Init';
import { availableLanguages, areWeNowIn, getRightCL } from './util/lang/i18n';
import { changeLanguage } from './util/lang/i18n';
import { Context } from './../main/State';
import { defaultLang } from './util/lang/i18n';
import { availableCountries } from './util/lang/i18n';
import SpringModal from './util/Modal';
const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: 'rgb(0,0,0,0)',
		color: 'white',
		transition: '0.5s',
	},
	scrolled: {
		flexGrow: 1,
		backgroundColor: 'white',
		color: 'white',
		boxShadow: '0px 0px 10px 5px rgb(0, 0, 0, 0.10)',
		transition: '0.5s',
		padding: '0px',
	},
	toolbar: {
		minHeight: 40,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		transition: '0.4s',
		paddingTop: theme.spacing(1),
	},
	scrolledToolbar: {
		minHeight: 30,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		paddingTop: '0px',
		transition: '0.4s',
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		flexGrow: 1,
	},
}));

const pagesWithSmallNav = ['/browse', '/locator'];

export default () => {
	const classes = useStyles();
	const [isS, setIsScrolled] = useState(false);
	const [openChangeLangModal, setOpenChangeLangModal] = useState(false);
	const location = useLocation();
	const [anchorEl, setAnchorEl] = useState(null);
	const history = useHistory();
	const { t, i18n } = useTranslation();
	const [state, dispatch] = useContext(Context);
	const { basicFooter } = state;

	let isScrolled = basicFooter
		? true
		: pagesWithSmallNav.find((page) => location.pathname.includes(page))
			? true
			: isS;

	useEffect(() => {
		if (isS) dispatch({ type: 'SET_FOOTERBASIC', payload: true });
		isScrolled = false;
	}, [isS]);

	const flag = () =>
		availableLanguages.find(
			(lang) => lang.symbol.toLowerCase() === i18n.language
		) ?
			<span
				className={` clickable d-flex align-items-center font-weight-bold pointer ${
					isScrolled ? 'text-dark' : 'text-white'
				}`}
			>
				{i18n.language === 'en' ? 'GB' : i18n.language.toUpperCase()}
			</span>
			:
			<span
				className={`clickable d-flex align-items-center pointer font-weight-bold ${
					isScrolled ? 'text-dark' : 'text-white'
				}`}
			>
        NL
			</span>
    ;

	function useQuery() {
		return new URLSearchParams(useLocation().search);
	}
	const query = useQuery();

	const changeLang = (lang) => {
		event(changedLanguage(lang));
		i18n.changeLanguage(lang);
		history.push({ pathname: changeLanguage(lang) });
	};
	useEffect(() => {
		if (
			query.has('language') &&
				availableLanguages.find(
					(lang) => lang.symbol.toLowerCase() === query.get('language')
				)
		) {
			i18n.changeLanguage(query.get('language'));
		}
	}, []);

	const openFlags = e => {
		setAnchorEl(e.currentTarget);
	};

	const openPop = Boolean(anchorEl);
	const id = openPop ? 'simple-popover' : undefined;

	const menuItems = [
		{
			Component: () =>
				<MenuItem
					arrowClassName={`${isScrolled ? 'text-dark' : 'text-white'}`}
					Icon={() =>
						<span className={`${isScrolled ? 'text-dark' : 'text-white'}`}>
							{t('More info')}
						</span>
					}
					options={[
						{
							text: <>{t('Store Locator')}</>,
							href: getRightCL('/locator'),
						},
						{
							text: t('About Us'),
							href: getRightCL('/about'),
						},
						{
							text: t('Vacancies'),
							href: getRightCL('/jobs'),
							disableOutCountries: ['nl'],
						},
						{
							text: t('Contact'),
							href: getRightCL('/contact'),
						},
						{
							text: t('PTMD online partners'),
							href: getRightCL('/partners'),
							disableOutCountries: ['nl'],
						},
						{
							text: 'Press',
							href: getRightCL('/press'),
						},
					]}
				/>
			,
			Icon: InfoIcon,
		},
		{
			Component: () =>
				<MenuItem
					href={getRightCL('/b2b')}
					Icon={() =>
						<span className={`${!isScrolled ? 'text-white' : 'text-dark'}`}>
              B2B
						</span>
					}
				/>
			,
		},
		{
			disableOutCountries: ['nl', 'be'],
			Component: () =>
				<MenuItem
					href={getRightCL('/partners')}
					Icon={() =>
						<span className={`${!isScrolled ? 'text-white' : 'text-dark'}`}>
              Partners
						</span>
					}
				/>
			,
		},
		{
			Component: () =>
				<MenuItem
					disableArrow
					Icon={() => flag()}
					options={availableLanguages.map((lang) => {
						return {
							className: ' mb-2',
							onClick: () => changeLang(lang.symbol.toLowerCase()),
							text:
								<>
									<span className="mr-3">{lang.symbol.toUpperCase()}</span>{' '}
									{lang.lang}
								</>
							,
						};
					})}
				/>
			,
		},
		{
			Component: () =>
				<MenuItem
					disableArrow
					Icon={() =>
						<span
							className={`flag-icon-${state.locale.country.toLowerCase()} flag-icon flag no-pointer`}
							aria-describedby={id}
							onClick={openFlags}
						/>
					}
					options={availableCountries
						.filter((c) => !c.inactive)
						.map((country) => {
							return {
								className: ' mb-2',
								onClick: () => setOpenChangeLangModal(country.symbol),
								text:
									<span
										onClick={() => setOpenChangeLangModal(country.symbol)}
										className={`pointer flag-icon flag-icon-${country.symbol.toLowerCase()} flag`}
									></span>
								,
							};
						})}
				/>
			,
		},
	];

	let shouldRunScroll = true;

	useEffect(() => {
		const listenForScroll = () => {
			if (
				window.scrollY > 20 &&
        window.scrollY < 100000000 &&
        isScrolled === false &&
        shouldRunScroll
			) {
				shouldRunScroll = false;
				setIsScrolled(true);
			} else if (window.scrollY === 0) {
				shouldRunScroll = true;
				setIsScrolled(false);
			}
		};
		window.addEventListener('scroll', listenForScroll);
		return () => window.removeEventListener('scroll', listenForScroll);
	}, []);

	const handleClose = () => {
		setAnchorEl(null);
	};

	const changeCnty = async (country) => {
		dispatch({
			type: 'SET_LOCALE',
			append: s => ({
				...s,
				locale: { ...state.locale, doneAsking: false },
			}),
		});
		whereAmI(`country=${country.toLowerCase()}`).then((payload) => {
			const foundCountry = payload.country.toLowerCase();
			const localCountry = localStorage.getItem('country');
			dispatch({
				type: 'SET_LOCALE',
				payload: {
					...payload,
					accurate:
						localCountry.toLowerCase() === country.toLowerCase(),
				},
			});
			history.push({
				pathname: `/${
					availableCountries.find((c) => c.symbol === foundCountry)
						? availableCountries.find((c) => c.symbol === foundCountry).lang
						: defaultLang
				}`,
			});
		}).catch((err) => {
			console.log(err, 'Can\'t determine language.');
			dispatch({
				type: 'SET_LOCALE',
				payload: { country: defaultLang.toUpperCase(), accurate: false },
			});
			history.push({ pathname: `/${i18n.language}` });
		});
		setOpenChangeLangModal(false);
	};

	return (
		<>
			<SpringModal
				open={openChangeLangModal ? true : false}
				background="white"
				className="rounded"
			>
				<div className="w-100 h-100 d-flex flex-column align-items-center">
					<span className="fs-13">
            It looks like you are in {state.locale.country.toUpperCase()},
					</span>
					<span className="fs-13 mb-4">
            Are you sure you want to switch to{' '}
						{(openChangeLangModal || '').toUpperCase()}?
					</span>
					<Button
						color="primary"
						fullWidth
						className="mb-2 mr-2"
						variant="contained"
						disableElevation
						size="small"
						onClick={() => {
							setOpenChangeLangModal(false);
							handleClose();
						}}
					>
            Stay in {state.locale.country?.toUpperCase()}
					</Button>
          or
					<Button
						color="secondary"
						fullWidth
						className="mt-2"
						variant="contained"
						disableElevation
						size="small"
						onClick={() => changeCnty(openChangeLangModal)}
					>
            Switch to {openChangeLangModal}
					</Button>
				</div>
			</SpringModal>
			<motion.div className={!isScrolled ? classes.root : classes.scrolled}>
				<AppBar
					elevation={0}
					position="fixed"
					className={!isScrolled ? classes.root : classes.scrolled}
				>
					<Container className="navbar">
						<Toolbar
							className={
								!isScrolled ? classes.toolbar : classes.scrolledToolbar
							}
						>
							<span className="logo-holder">
								<Link to={getRightCL('/')}>
									<img
										src="/images/PTMDgold.png"
										className="navbar-logo"
										alt="PTMD logo"
										style={{ maxWidth: !isScrolled ? '70px' : '40px' }}
									/>
								</Link>
							</span>
							<div
								style={{ display: 'flex', transition: '0.2s' }}
								className="navbar-menu"
							>
								{menuItems.map((menuItem, i) => {
									if(menuItem.disableOutCountries && !areWeNowIn(state, menuItem.disableOutCountries)){
										return null;
									}
									const { Component } = menuItem;
									return (
										<div
											key={i}
											className="nav-item ml-2"
											style={{
												background: !isScrolled
													? 'rgba(0, 0, 0, 0.7)'
													: 'white',
											}}
										>
											<Component />
										</div>
									);
								})}
							</div>
						</Toolbar>
					</Container>
				</AppBar>
			</motion.div>
		</>
	);
};
