import React from 'react';
import './Footer.scss';
import { Container } from '@material-ui/core';
import BasicComponent from './blocks/BasicComponent';
import YouTubeIcon from '@material-ui/icons/YouTube';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import PinterestIcon from '@material-ui/icons/Pinterest';
import { useTranslation } from 'react-i18next';
import { getRightCL } from './util/lang/i18n';
import { Link } from 'react-router-dom';

const contact = [
	'Pot en Mand BV',
	'PTMD Collection',
	'Pietseweg 13A',
	'4751 RT Oud Gastel',
	'Tel. + 31 (0) 16 551 70 00',
	'E-mail info@ptmd.nl',
	'Kvk nr. 24285514',
	'BTW nr. NL806933379B01',
];

export default () => {
	const { t } = useTranslation();

	return (
		<div className="w-100 d-flex flex-column footer-holder">
			<BasicComponent
				color="white"
				height="100%"
				style={{
					padding: '70px 20px 100px 20px',
					background: 'rgb(19, 19, 23)',
					borderRadius: '0px',
				}}
			>
				<Container className="footer d-flex justify-content-between flex-column-reverse flex-md-row">
					<div className="d-flex justify-content-end flex-column flex-sm-column flex-md-row">
						<div className="d-flex flex-column align-items-start mb-2 m-2 mt-2">
							<div>
								<span className="white fs-13">{t('Customer service')}</span>
							</div>
							<div>
								<Link to={getRightCL('/faq')}>
									<span className="white fs-10">{t('Frequently Asked Questions')}</span>
								</Link>
							</div>
							<div>
								<Link to={getRightCL('/termsandconditions')}>
									<span className="white fs-10">{t('Terms and Conditions')}</span>
								</Link>
							</div>
							<div>
								<a href="https://ptmd.nl/Privacy%20Policy.pdf">
									<span className="white fs-10">{t('Privacy Policy')}</span>
								</a>
							</div>
							<div>
								<Link to={getRightCL('/complaints')}>
									<span className="white fs-10">{t('Complaints')}</span>
								</Link>
							</div>
							<div>
								<Link to={getRightCL('/warranty')}>
									<span className="white fs-10">{t('Guarantee')}</span>
								</Link>
							</div>
						</div>
						<div className="d-flex flex-column align-items-start mb-2 m-2 mt-2">
							<div>
								<span className="white fs-13">{t('About Us')}</span>
							</div>
							<div>
								<Link to={getRightCL('/about')}>
									<span className="white fs-10">{t('About Us')}</span>
								</Link>
							</div>
							<div>
								<Link to={getRightCL('/jobs')}>
									<span className="white fs-10">{t('Vacancies')}</span>
								</Link>
							</div>
							<div>
								<Link to={getRightCL('/contact')}>
									<span className="white fs-10">{t('Contact')}</span>
								</Link>
							</div>
							<div>
								<Link to={getRightCL('/partners')}>
									<span className="white fs-10">{t('PTMD online partners')}</span>
								</Link>
							</div>
							<div>
								<a href="https://b2b.ptmd.nl/shop/login">
									<span className="white fs-10">{t('B2B')}</span>
								</a>
							</div>
							<div>
								<Link to={getRightCL('/press')}>
									<span className="white fs-10">{t('Press')}</span>
								</Link>
							</div>
						</div>
						<div className="d-flex flex-column align-items-start fullWidth mb-2 m-2 mt-2">
							<div>
								<span className="white fs-13">{t('Contact')}</span>
							</div>
							{contact.map((service, i) => {
								return (
									<div key={i}>
										<span className="white fs-10">{service}</span>
									</div>
								);
							})}
						</div>
						<div className=" d-flex flex-row flex-md-column align-items-center justify-content-around m-1 mb-2 mt-2">
							<a
								href={'https://www.facebook.com/ptmdcollection/'}
								target="_blank"
								rel="noopener noreferrer"
							>
								<FacebookIcon fontSize="large" style={{ color: 'white' }} />
							</a>

							<a
								href="https://www.instagram.com/ptmdcollection/"
								target="_blank"
								rel="noopener noreferrer"
							>
								<InstagramIcon fontSize="large" style={{ color: 'white' }} />
							</a>

							<a
								href="https://www.youtube.com/channel/UCnohtesTPUKLeXSq2MfEGow"
								target="_blank"
								rel="noopener noreferrer"
							>
								<YouTubeIcon fontSize="large" style={{ color: 'white' }} />
							</a>

							<a
								href="https://nl.pinterest.com/ptmdcollection/"
								target="_blank"
								rel="noopener noreferrer"
							>
								<PinterestIcon fontSize="large" style={{ color: 'white' }} />
							</a>
						</div>
					</div>
				</Container>
			</BasicComponent>
		</div>
	);
};
