import Axios from 'axios';

const imageUrl = 'https://api.ptmd.nl/rest/images/v1';

const getData = res => res.data;

const myAxios = Axios.create({
	baseURL: process.env.REACT_APP_B2C_API
});

// GET
export function home(country) {
	return myAxios.get(`/home?${country}`).then(getData);
}
export function newestItems() {
	return myAxios.get('/newestItems').then(getData);
}
export function webshopItems(params) {
	return myAxios.get(`/ptmditems${params? `?${params}` : ''}`).then(getData);
}
export function preloadStores(kind) {
	return myAxios.get(`/storesv2/preload/${kind}`).then(getData);
}
export function storeLocatorSurroudingStores(long, lat, kind) {
	return myAxios.get(`/storesv2/${long}/${lat}/${kind}`).then(getData);
}
export function getItem(sku, params) {
	return myAxios.get(`/sku/${sku}?${params}`).then(getData);
}
export function getItems(skus, params) {
	return myAxios.get(`/skus/${skus}?${params}`).then(getData);
}
export function getCatalog(c) {
	return myAxios.get(`/catalogs/${c}`).then(getData);
}
export function catalogsPage(sku) {
	return myAxios.get(`/catalogs/page/${sku}`).then(getData);
}
export function categories() {
	return myAxios.get('/categorieswithproductgroups').then(getData);
}
export function getImageUrl(size, endpoint) {
	return `${imageUrl}/${size}/${endpoint}`;
}
export function whereAmI(country) {
	return myAxios.get(`/whereami?${country}`).then(getData);
}
export function getWPContent(page) {
	return myAxios.get(`/cms/${page}`).then(getData);
}

// POST
export function newsLetter(email) {
	return myAxios.post('/newsletter', { email }).then(getData);
}
export function getStores(data) {
	return myAxios.post('/storesv2/preload/stores', data).then(getData);
}
export function search(data) {
	return myAxios.post('/getsuggestions', data).then(getData);
}
export function storeLocator(searchTerm = '', lat, long, stores, limit) {
	return myAxios.post('/storesv3/find', { searchTerm, lat, long, stores, limit }).then(getData);
}
