import React from 'react';
import './App.scss';
import Routing from './main/Routing';
import { ThemeProvider } from '@material-ui/styles';
import { generalTheme } from './main/Theming';

import './Components/extra/fonts.css';
import Store from './main/State';

function App() {
	return (
		<ThemeProvider theme={generalTheme}>
			<Store>
				<Routing />
			</Store>
		</ThemeProvider>
	);
}

export default App;
